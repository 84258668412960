import { lazy } from "react";
import AdminGuard from "../components/AdminGuard";
import DashboardLayout from "../layouts/DashboardLayout";
import RoutesManager from "./RoutesManager";

const adminURIS = {
  CREATE_EXAM: "/create-exam",
  CREATE_FLASHCARD: "/create-flashcard",
  EDIT_EXAM: "/edit-exam",
  DRAFT_EXAMS: "/draft-exams",
  TRASHED_EXAMS: "/trashed-exams",
  LIST_VOCABULARIES: "/list-vocabularies",
  LIST_USERS: "/list-users",
  LIST_TEACHERS: "/list-teachers",
  CREATE_BLOG: "/create-blog",
  LIST_BLOGS: "/list-blogs",
  EDIT_BLOG: "/edit-blog",
  CREATE_COUPON: "/create-coupon",
  LIST_COUPONS: "/list-coupons",
  REPORTED_BUGS: "/report/bugs",
  REPORTED_EXAMS: "/report/exams",
  AD_SLOTS: "/ad-slots",
  AD_SETTINGS: "/ad-settings",
  LIST_BUNDLES: "/list-bundles",
  CREATE_BUNDLE: "/create-bundle",
  CUSTOMIZE_BUNDLE: "/customize-bundle",
  COMPONENT_LIBRARY: "/components",
  SPLASH: "/splash",
  CREATE_VOCABULARY: "/create-vocabulary",
  EDIT_VOCABULARY: "/edit-vocabulary",
};

const routes = [
  {
    path: adminURIS.COMPONENT_LIBRARY,
    element: lazy(() => import("../views/Dashboard/Admin/ComponentLibrary")),
  },
  {
    path: adminURIS.CREATE_FLASHCARD,
    element: lazy(() => import("../views/v2/Admin/FlashCards/CreateFlashCard")),
  },
  {
    path: adminURIS.SPLASH,
    element: lazy(() => import("../components/SplashScreen")),
  },
  {
    path: adminURIS.CREATE_EXAM,
    element: lazy(
      () => import("../views/v2/Admin/CreateExamView/CreateExamView")
    ),
  },
  {
    path: adminURIS.DRAFT_EXAMS,
    element: lazy(() => import("../views/v2/Admin/DraftExams/DraftExams")),
  },
  {
    path: adminURIS.TRASHED_EXAMS,
    element: lazy(() => import("../views/v2/Admin/TrashedExams/TrashedExams")),
  },
  {
    path: adminURIS.LIST_VOCABULARIES,
    element: lazy(
      () => import("../views/v2/Admin/Vocabularies/ListVocabularies")
    ),
  },
  {
    path: `${adminURIS.EDIT_VOCABULARY}/:vocabularyId`,
    element: lazy(
      () => import("../views/v2/Admin/Vocabularies/EditVocabulary")
    ),
  },
  {
    path: `${adminURIS.EDIT_EXAM}/:aliasId`,
    element: lazy(() => import("../views/v2/Admin/EditExamView/EditExamView")),
  },
  {
    path: adminURIS.LIST_USERS,
    element: lazy(() => import("../views/ListUsers/ListUsers")),
  },
  {
    path: adminURIS.LIST_TEACHERS,
    element: lazy(() => import("../views/ListTeachers/ListTeachers")),
  },
  {
    path: adminURIS.CREATE_BLOG,
    element: lazy(() => import("../views/Blogs/CreateBlog")),
  },
  {
    path: adminURIS.LIST_BLOGS,
    element: lazy(() => import("../views/Blogs/ListBlogs")),
  },
  {
    path: `${adminURIS.EDIT_BLOG}/:slug`,
    element: lazy(() => import("../views/Blogs/EditBlog")),
  },
  {
    path: adminURIS.LIST_COUPONS,
    element: lazy(() => import("../views/Coupon/ListCoupons")),
  },
  {
    path: adminURIS.CREATE_COUPON,
    element: lazy(() => import("../views/Coupon/CreateCoupon")),
  },
  {
    path: adminURIS.REPORTED_BUGS,
    element: lazy(() => import("../views/Reports/ReportedBug")),
  },
  {
    path: adminURIS.REPORTED_EXAMS,
    element: lazy(() => import("../views/Reports/ReportedExam")),
  },
  {
    path: adminURIS.AD_SLOTS,
    element: lazy(() => import("../views/AdSlots/AdSlots")),
  },
  {
    path: `${adminURIS.AD_SETTINGS}/:adSlug`,
    element: lazy(() => import("../views/AdSettings/AdSettings")),
  },
  {
    path: adminURIS.LIST_BUNDLES,
    element: lazy(() => import("../views/Bundles/ListBundles")),
  },
  {
    path: `${adminURIS.CUSTOMIZE_BUNDLE}/:bundleId`,
    element: lazy(() => import("../views/Bundles/CustomizeBundle")),
  },
  {
    path: adminURIS.CREATE_BUNDLE,
    element: lazy(() => import("../views/Bundles/CreateBundle")),
  },
  {
    path: `${adminURIS.AD_SETTINGS}/:adSlug`,
    element: lazy(() => import("../views/AdSettings/AdSettings")),
  },
  {
    path: `${adminURIS.CREATE_VOCABULARY}`,
    element: lazy(
      () => import("../views/v2/Admin/Vocabularies/CreateVocabulary")
    ),
  },
];
const config = {
  guard: AdminGuard,
  layout: DashboardLayout,
  prefix: "/app/admin",
};

const adminRoutesManager = new RoutesManager(config, adminURIS, routes);

export default adminRoutesManager;
