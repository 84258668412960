import axios, { AxiosRequestConfig } from "axios";

const token = localStorage.getItem("exampalace_access_token");

// export const baseURL = `http://${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_PORT}/api/`;
export const baseURL = `${
  process.env.REACT_APP_BASE_URL || "http://localhost"
}/api/`;

const axiosConfig: AxiosRequestConfig = {
  baseURL,
  headers: {
    Authorization: token,
  },
};

export default axios.create(axiosConfig);
