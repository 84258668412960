import {
  AppBar,
  Container,
  Slide,
  Stack,
  Theme,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
  alpha,
} from "@mui/material";
import * as React from "react";

function HideOnScroll(props: {
  children: React.ReactElement;
  window?: () => Window | undefined;
}) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  if (matches) {
    return children;
  }
  return (
    <Slide appear={false} direction="down" in={!trigger} timeout={100}>
      {children}
    </Slide>
  );
}

type HideOnScrollHeaderProps = {
  children: React.ReactElement;
};

const HideOnScrollHeader = (props: HideOnScrollHeaderProps) => {
  const { children } = props;
  const theme = useTheme();

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar
          position="fixed"
          sx={{
            backdropFilter: {
              xs: "blur(1px)",
            },
            background: {
              xs: `linear-gradient(to top, rgba(0,0,0,0),${alpha(
                theme.palette.background.paper,
                0.35
              )},${alpha(theme.palette.background.paper, 0.6)}, ${alpha(
                theme.palette.background.paper,
                1
              )})`,
              sm: `linear-gradient(to top, rgba(0, 0, 0, 0), ${theme.palette.background.paper})`,
              md: `linear-gradient(to top, ${theme.palette.background.paper}, ${theme.palette.background.paper})`,
              lg: `linear-gradient(to top, ${theme.palette.background.paper}, ${theme.palette.background.paper})`,
            },
            backgroundColor: {
              lg: "background.paper",
              xs: "transparent",
            },
            boxShadow: 2,
          }}
        >
          <Container maxWidth={false} disableGutters>
            <Stack paddingX={2}>{children}</Stack>
          </Container>
        </AppBar>
      </HideOnScroll>
    </>
  );
};
export default HideOnScrollHeader;
